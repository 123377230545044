<template>
  <div class="office">
   <h1>Location: {{office.name}}</h1>
    <br/>
    City: {{ office.city }}<br/>
    State: {{ office.state }}<br/>
    Address: {{ office.address }}<br/>
    PhoneNumber: {{ office.phoneNumber }}<br/>
   <br/>    
   <form @submit.prevent="save">
      Is virtual only: <input type="checkbox" v-model="office.isVirtualOnly"><br/>
      Zip Codes: <input type="text" v-model="office.zipCodes"><br/>
      Private Rooms Count: <input type="text" v-model="office.privateRoomsCount"><br/>
      <br/>
      <button type="submit" class="small">Save</button>
    </form>
  </div>
</template>

<script>
import officesService from '../services/offices';

export default {
  name: 'Office',
  props: ["id"],
  data: () => {
    return {
      office: null
    };
  },
  async mounted() {
    this.office = await officesService.getById(this.id);
  },
  methods: {
    save: async function() {
        await officesService.save(this.office);
        this.$toastr.s("Saved");
    }
  }
}
</script>